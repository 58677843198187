$(function () {
    // http:// or https:// urlcheme validator
    // Has validator function removes this warning from console: "validator <validatorname> is already defined."
    if (typeof window.Parsley !== "undefined") {
        if (!window.Parsley.hasValidator('urlscheme')) {
            window.Parsley.addValidator('urlscheme', {
                requirementType: 'boolean',
                validateString: function (value) {
                    return (value.indexOf("http://") == 0 || value.indexOf("https://") == 0);
                }
            });
        }

        if (!window.Parsley.hasValidator('youtubescheme')) {
            window.Parsley.addValidator('youtubescheme', {
                requirementType: 'boolean',
                validateString: function (value) {
                    return (value.indexOf("https://youtu.be") == 0 || value.indexOf("https://www.youtube.com/watch?") == 0);
                }
            });
        }

        if (!window.Parsley.hasValidator('acceptimage')) {
            window.Parsley.addValidator('acceptimage', {
                requirementType: 'string',
                validateString: function (value, acceptimage) {
                    if (value === undefined || value === null || value === "") {
                        return true;
                    }
                    // Removes all the dots from the supplied data-parsley-acceptimage input parameter e.g. .png becomes png
                    // This was done since the fileExtension removes the dot from the front of the word. It could have been added there but decided to just compare words only
                    // You could supply the parameter without the dots e.g. png,jpg instead of .png,.jpg
                    acceptimage = acceptimage.replace(/\./ig, "");
                    var argByComma = acceptimage.split(",");
                    var fileExtension = value.split('.').pop();
                    function isFileExtensionValid(fileExt) {
                        return fileExtension.toLowerCase().indexOf(fileExt.toLowerCase()) !== -1;
                    }
                    return argByComma.some(isFileExtensionValid);
                }
            });
        }

        if (!window.Parsley.hasValidator('acceptvideo')) {
            window.Parsley.addValidator('acceptvideo', {
                requirementType: 'string',
                validateString: function (value, acceptvideo) {
                    if (value === undefined || value === null || value === "") {
                        return true;
                    }
                    // Removes all the dots from the supplied data-parsley-acceptvideo input parameter e.g. .mp4 becomes mp4.
                    // This was done since the fileExtension removes the dot from the front of the word. It could have been added there but decided to just compare words only
                    // You could supply the parameter without the dots e.g. mp4,flv instead of .mp4,.flv
                    acceptvideo = acceptvideo.replace(/\./ig, "");
                    var argByComma = acceptvideo.split(",");
                    var fileExtension = value.split('.').pop();
                    function isFileExtensionValid(fileExt) {
                        return fileExtension.toLowerCase().indexOf(fileExt.toLowerCase()) !== -1;
                    }
                    return argByComma.some(isFileExtensionValid);
                }
            });
        }

        if (!window.Parsley.hasValidator('wordcountandlength')) {
            window.Parsley.addValidator('wordcountandlength', {
                requirementType: 'string',
                validateString: function (value, wordCountAndLength) {
                    // We let through if the value is empty because the input might not be required. The html5 required tag should take care of it.
                    if (value === undefined || value === null || value === "") {
                        return true;
                    }

                    var argByComma = wordCountAndLength.split(",");
                    var maxWordCount = Number(argByComma[0]);
                    var maxWordLength = Number(argByComma[1]);
                    var valueByComma = value.split(",");

                    function isWordSmallerThanMaxLength(word) {
                        return word.length <= maxWordLength;
                    }

                    if (valueByComma.length > 0 && valueByComma.length <= maxWordCount) {
                        return valueByComma.every(isWordSmallerThanMaxLength);
                    }
                    return false;
                }
            });
        }
    }
});
